import { post_request } from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  CONFIRM_EMAIL_ENDPOINT,
  CONFIRM_EMAIL_REDUX,
  RESEND_EMAIL_ENDPOINT,
  RESEND_EMAIL_REDUX,
} from './VerifyEmailConstants.js';

const resendEmailAction = post_request(
  RESEND_EMAIL_REDUX,
  RESEND_EMAIL_ENDPOINT,
  DICTIONARY.pureRequest,
);

const confirmEmailAction = post_request(
  CONFIRM_EMAIL_REDUX,
  CONFIRM_EMAIL_ENDPOINT,
  DICTIONARY.pureRequest,
);

export { confirmEmailAction };
export default resendEmailAction;
