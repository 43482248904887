import { Box, styled } from '@mui/material';

const DashboardNavbarRoot = styled(Box)(() => ({
  padding: '50px 50px 50px 0px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'transparent',
  backdropFilter: 'blur(10px)',
  zIndex: '100',
  position: 'fixed',
}));

const LogoRoot = styled(Box)(() => ({
  paddingLeft: '50px',
  textAlign: 'left',
  minWidth: 300,
}));

const ToolbarRoot = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '15px',
  width: '100%',
  alignItems: 'center',
}));

export { LogoRoot, ToolbarRoot };
export default DashboardNavbarRoot;
