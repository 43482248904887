import { post_request } from 'api/Requests.js';

import { RESET_ENDPOINT, RESET_LINK_REDUX } from './ResetPassConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const resetPasswordAction = post_request(
  RESET_LINK_REDUX,
  RESET_ENDPOINT,
  DICTIONARY.pureRequest,
);

export default resetPasswordAction;
