/* Object class for get/set API Headers */
const Headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',

  get apiHeaders() {
    return {
      Accept: this.Accept,
      'Content-Type': this['Content-Type'],
    };
  },

  set apiAccept(accept) {
    this.Accept = accept;
  },

  set apiContentType(contentType) {
    this['Content-Type'] = contentType;
  },
};

const decodeArrayParameters = (paramsObject, url) => {
  for (let key in paramsObject) {
    const currentParameter = paramsObject[key];

    if (currentParameter) {
      const decodeArray = currentParameter.map((value) => `${key}=${value}`);
      url = `${url}?$${decodeArray.join('&')}`;
    }
  }

  return url;
};

export { decodeArrayParameters };
export default Headers;
