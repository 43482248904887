import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';

import App from './App';
import store from 'store/configureStore';

import TitleContextProvider from 'contexts/TitleContext';

import './styles/normalize.css';
import './styles/index.css';
import './styles/App.css';
import 'nprogress/nprogress.css';
import 'simplebar/dist/simplebar.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TitleContextProvider>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </BrowserRouter>
      </TitleContextProvider>
    </LocalizationProvider>
  </Provider>,
);
