/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import ScrollBar from 'simplebar-react';
import { Outlet } from 'react-router';

import DashboardSidebar from './dashboardSidebar/DashboardSidebar';
import DashboardWrapper from './dashboardWrapper/DahsboardWrapper';
import DashboardNavbar from './dashboardNavbar/DashboardNavbar';
import ScreenLoader from 'UI/ScreenLoader/ScreenLoader';

import useLoginDispatcher from 'pages/login/hooks/useLoginDispatcher';

import DICTIONARY from 'core/Dictionary';

const DashboardLayout = ({ children }) => {
  const { getUserDispatcher } = useLoginDispatcher();
  const [user, setUser] = useState(null);

  const getUserFetcher = (payload) => {
    const requestState = {
      payload,
    };

    getUserDispatcher(requestState)
      .unwrap()
      .then((response) => {
        localStorage.setItem(DICTIONARY.user, JSON.stringify(response));
        setUser(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserFetcher();
  }, []);

  if (!user) return <ScreenLoader />;

  return (
    <Fragment>
      <DashboardSidebar />
      <DashboardNavbar user={user} />
      <DashboardWrapper>
        <ScrollBar>{children || <Outlet />}</ScrollBar>
      </DashboardWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
