import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import themePalette from 'theme/Palette';

const LinkRoot = styled(Link)(({ active }) => ({
  fontSize: '18px',
  fontWeight: active ? 500 : 400,
  textDecoration: 'none',
  color: active ? themePalette.textPrimary : themePalette.textPrimary,
  transition: 'all .3s',
  textTransform: 'capitalize',
  cursor: active ? 'unset' : 'pointer',
  '&:hover': {
    textDecoration: active ? 'none' : 'underline',
  },
}));

export default LinkRoot;
