import { createSlice } from '@reduxjs/toolkit';

import selectSecondaryAction from './ClassesActions';
import { CLASSES_REDUX } from './ClassesConstants';

const initialState = {
  secondaryUpdating: false,
};

const classesSlice = createSlice({
  name: CLASSES_REDUX,
  initialState,
  reducers: {
    setSecondaryUpdating: (state, payload) => {
      state.secondaryUpdating = payload;
    },
  },
  extraReducers: {
    [selectSecondaryAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectSecondaryAction.fulfilled.type]: (state) => {
      state.secondaryUpdating = false;
    },
    [selectSecondaryAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.secondaryUpdating = false;
    },
  },
});

export const { setSecondaryUpdating } = classesSlice.actions;
export default classesSlice.reducer;
