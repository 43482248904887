import { Avatar } from '@mui/material';
import React, { Fragment } from 'react';

const ProfilePopover = ({ overrideStyles, src }) => {
  return (
    <Fragment>
      <Avatar src={src || null} sx={overrideStyles} />
    </Fragment>
  );
};

export default ProfilePopover;
