import { createSlice } from '@reduxjs/toolkit';

import { FEEDBACK_REDUX } from './ContactUsConstants.js';
import createFeedbackAction from './ContactUsActions.js';

const initialState = {
  feedbackLoading: false,
};

const feedbackSlice = createSlice({
  name: FEEDBACK_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [createFeedbackAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.feedbackLoading = true;
    },
    [createFeedbackAction.fulfilled.type]: (state) => {
      state.feedbackLoading = false;
    },
    [createFeedbackAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.feedbackLoading = false;
    },
  },
});

export default feedbackSlice.reducer;
