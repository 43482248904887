/* eslint-disable react-hooks/exhaustive-deps */
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import QuizIcon from '@mui/icons-material/Quiz';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import FeedIcon from '@mui/icons-material/Feed';
import { Box } from '@mui/material';

import LogoutModule from 'pages/login/modules/LogoutModule';

import SidebarWrapper, {
  ListRoot,
  LogoHeader,
  LogoWrapper,
  MenuItem,
} from './styled';
import DashboardMenuItem from 'layout/dashboardMenuItem/DashboardMenuItem';
import Flexbox from 'UI/Flexbox/Flexbox';
import menuList from 'layout/menuList';

import { CONTACT_US_PATH, LANDING_PATH } from 'routes/path';
import Logo from 'assets/components/Logo';

const DashboardSidebar = () => {
  const [activeMenu, setActiveMenu] = useState(menuList[0]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (menu) => (_event) => {
    setActiveMenu(menu);
    navigate(menu.route);
  };

  const handleNavigateContact = (_event) => {
    navigate(CONTACT_US_PATH);
  };

  const handleNavigateLanding = () => {
    navigate(LANDING_PATH);
  };

  const handleBlogNavigate = () => {
    window.open('https://e2grade.info/blog/', '_target');
  };

  const handleTripTricksNavigate = () => {
    window.open('https://e2grade.info/tip/', '_target');
  };

  const handleProgramGuideNavigate = () => {
    window.open('https://e2grade.info/program-guide/', '_target');
  };

  const handleFaqNavigate = () => {
    window.open('https://e2grade.info/faq/', '_target');
  };

  /* Effect for set correct menu after reloading */
  useEffect(() => {
    menuList.forEach((menu) => {
      if (menu.route === pathname) {
        setActiveMenu(menu);
      }
    });
  }, []);

  return (
    <SidebarWrapper>
      <LogoWrapper>
        <Box onClick={handleNavigateLanding} sx={{ cursor: 'pointer' }}>
          <Logo width={60} height={60} />
        </Box>
        <LogoHeader>E2Grade.</LogoHeader>
      </LogoWrapper>

      <Flexbox flexDirection="column" position="relative" height="100%">
        <ListRoot>
          {menuList.map((menu) => (
            <DashboardMenuItem
              label={menu.label}
              icon={menu.icon}
              route={menu.route}
              onClick={handleNavigate(menu)}
              active={menu === activeMenu ? 1 : 0}
              key={menu.label}
            />
          ))}
          <DashboardMenuItem
            label="Program Guide"
            icon={<AutoStoriesIcon />}
            route={null}
            onClick={handleProgramGuideNavigate}
          />
          <DashboardMenuItem
            label="Tips and Tricks"
            icon={<TipsAndUpdatesIcon sx={{ paddingLeft: '3px' }} />}
            route={null}
            onClick={handleTripTricksNavigate}
          />
          <DashboardMenuItem
            label="Blog"
            icon={<FeedIcon />}
            route={null}
            onClick={handleBlogNavigate}
          />
          <DashboardMenuItem
            label="FAQ"
            icon={<QuizIcon />}
            route={null}
            onClick={handleFaqNavigate}
          />
        </ListRoot>

        <MenuItem
          sx={{ position: 'absolute', bottom: '120px' }}
          onClick={handleNavigateContact}
        >
          <ContactSupportOutlinedIcon />
          <p>Contact Us</p>
        </MenuItem>

        <LogoutModule />
      </Flexbox>
    </SidebarWrapper>
  );
};

export default DashboardSidebar;
