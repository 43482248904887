import axios from 'axios';
import jwt from 'jwt-decode';

import DICTIONARY from 'core/Dictionary.js';
import Headers from './HeaderApi.js';
import { PAYMENT_PATH } from 'routes/path.js';

const API_URL = process.env.REACT_APP_API_URL;
const REFRESH_ENDPOINT = '/account/token/refresh';

/* Instance for requests w/o any tokens */
const pureInstance = axios.create({
  baseURL: API_URL,
  headers: Headers.apiHeaders,
});

pureInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

pureInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data.error),
);

/* Instance for requests with tokens */
const tokenInstance = axios.create({
  baseURL: API_URL,
  headers: Headers.apiHeaders,
});

tokenInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(DICTIONARY.accessToken);

    if (!accessToken) {
      // do something
    } else config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => {
    const responseError = error.response;
    return Promise.reject(
      (responseError && responseError.data) || 'Something went wrong',
    );
  },
);

tokenInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const Unauthorized = error.response.status === 401;
    const InternalError = error.response.status === 500;
    const PaymentError = error.response.status === 402;

    const accessToken = localStorage.getItem(DICTIONARY.accessToken);
    const refreshToken = localStorage.getItem(DICTIONARY.refreshToken);
    const decodeToken = jwt(accessToken);

    if (PaymentError) {
      localStorage.clear();
      window.location.href = PAYMENT_PATH;
    }

    if (
      decodeToken.exp * 1000 < new Date().getTime() &&
      Unauthorized &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const payload = { refresh: refreshToken };
        const endpoint = API_URL + REFRESH_ENDPOINT;
        const response = await axios.post(endpoint, payload);
        const accessToken = response.data.access;
        localStorage.setItem(DICTIONARY.accessToken, accessToken);

        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return tokenInstance(originalRequest);
      } catch (error) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }

    if (Unauthorized && originalRequest._retry) {
      localStorage.clear();
      window.location.href = '/login';
    }

    if (Unauthorized) {
      localStorage.clear();
      window.location.href = '/login';
    }

    if (InternalError) alert('Internal Server Error!');

    return Promise.reject(error);
  },
);

export { pureInstance, tokenInstance, REFRESH_ENDPOINT };
export default API_URL;
