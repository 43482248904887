const DICTIONARY = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  pureRequest: 'PURE',
  tokenRequest: 'TOKEN',
  user: 'user',
  userPk: 'user_pk',
  userToken: 'token',
  userEmail: 'userEmail',
  classId: 'classId',
  gradebookId: 'gradebookId',
  historyPath: 'historyPath',
};

export default DICTIONARY;
