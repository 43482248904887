import {
  delete_request,
  get_request,
  post_request,
  put_request,
} from 'api/Requests.js';

import {
  GENDER_SELECT_ENDPOINT,
  GENDER_SELECT_REDUX,
  GSNI_SELECT_ENDPOINT,
  GSNI_SELECT_REDUX,
  PRE_SELECT_ENDPOINT,
  PRE_STUDENTS_ENDPOINT,
  STUDENTS_CREATE_ENDPOINT,
  STUDENTS_CREATE_REDUX,
  STUDENTS_DELETE_REDUX,
  STUDENTS_SELECT_REDUX,
  STUDENTS_UPDATE_REDUX,
} from './StudentsConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectStudentsAction = get_request(
  STUDENTS_SELECT_REDUX,
  PRE_SELECT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createStudentsAction = post_request(
  STUDENTS_CREATE_REDUX,
  STUDENTS_CREATE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeStudentsAction = put_request(
  STUDENTS_UPDATE_REDUX,
  PRE_STUDENTS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteStudentsAction = delete_request(
  STUDENTS_DELETE_REDUX,
  PRE_STUDENTS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectGenderAction = get_request(
  GENDER_SELECT_REDUX,
  GENDER_SELECT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectGSNIAction = get_request(
  GSNI_SELECT_REDUX,
  GSNI_SELECT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  createStudentsAction,
  changeStudentsAction,
  deleteStudentsAction,
  selectGenderAction,
  selectGSNIAction,
};
export default selectStudentsAction;
