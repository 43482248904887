import { useDispatch, useSelector } from 'react-redux';

import loginAction, {
  getUserAction,
  logoutAction,
} from '../services/LoginActions';

const useLoginDispatcher = () => {
  const dispatch = useDispatch();
  const { loginLoading } = useSelector((state) => state.loginReducer);

  const loginDispatcher = (parameters) => {
    return dispatch(loginAction(parameters));
  };

  const getUserDispatcher = (parameters) => {
    return dispatch(getUserAction(parameters));
  };

  const logoutDispatcher = (parameters) => {
    return dispatch(logoutAction(parameters));
  };

  return {
    loginLoading,
    loginDispatcher,
    getUserDispatcher,
    logoutDispatcher,
  };
};

export default useLoginDispatcher;
