const PRE_GRADEBOOK_ENDPOINT = '/school/gradebook';
const PRE_STUDENT_GRADEBOOK_ENDPOINT = '/school/students-gradebook';
const PRE_WORKHABIT_ENDPOINT = '/school/workhabit';
const PRE_STUDENT_WORKHABIT_ENDPOINT = '/school/students-gradebook-workhabits';
const PRE_OBJECTIVE_ENDPOINT = '/school/workhabit/objective';

const SELECT_GSNI_ENDPOINT = '/school/gsni/info';
const SELECT_STUDENT_WORKHABITS_ENDPOINT =
  '/students-gradebook-workhabits/info';
const SELECT_STUDENT_GRADEBOOK_ENDPOINT = '/students-gradebook/info';
const CREATE_OBJECTIVE_ENDPOINT = '/school/workhabit/objective/create';
const CREATE_WORKHABIT_ENDPOINT = '/school/workhabit/create';
const UPDATE_WORKHABIT_ENDPOINT = '/change';
const UPDATE_STUDENT_WORKHABIT_ENDPOINT = '/change';
const UPDATE_STUDENT_GRADEBOOK_ENDPOINT = '/change';
const UPDATE_OBJECTIVE_ENDPOINT = '/change';
const DELETE_WORKHABIT_ENDPOINT = '/delete';
const DELETE_OBJECTIVE_ENDPOINT = '/delete';

const HABITS_REDUX = 'workHabits';
const SELECT_GSNI_REDUX = 'workHabits/gsni';
const SELECT_STUDENT_WORKHABIT_REDUX = 'workHabits/selectStudentWorkhabit';
const SELECT_STUDENT_GRADEBOOK_REDUX = 'workHabits/selectStudentGradebook';
const CREATE_OBJECTIVE_REDUX = 'workHabits/createObjective';
const CREATE_WORKHABIT_REDUX = 'workHabits/createWorkhabit';
const UPDATE_STUDENT_GRADEBOOK_REDUX = 'workHabits/updateStudentGradebook';
const UPDATE_WORKHABIT_REDUX = 'workHabits/updateWorkhabit';
const UPDATE_OBJECTIVE_REDUX = 'workHabits/updateObjectives';
const UPDATE_STUDENT_WORKHABIT_REDUX = 'workHabits/updateStudentWorkhabits';
const DELETE_WORKHABIT_REDUX = 'workHabits/deleteWorkhabit';
const DELETE_OBJECTIVE_REDUX = 'workHabits/deleteObjective';

export {
  PRE_GRADEBOOK_ENDPOINT,
  PRE_STUDENT_GRADEBOOK_ENDPOINT,
  PRE_WORKHABIT_ENDPOINT,
  PRE_STUDENT_WORKHABIT_ENDPOINT,
  PRE_OBJECTIVE_ENDPOINT,
  SELECT_GSNI_ENDPOINT,
  SELECT_STUDENT_WORKHABITS_ENDPOINT,
  SELECT_STUDENT_GRADEBOOK_ENDPOINT,
  CREATE_WORKHABIT_ENDPOINT,
  CREATE_OBJECTIVE_ENDPOINT,
  UPDATE_WORKHABIT_ENDPOINT,
  UPDATE_STUDENT_GRADEBOOK_ENDPOINT,
  UPDATE_STUDENT_WORKHABIT_ENDPOINT,
  UPDATE_OBJECTIVE_ENDPOINT,
  DELETE_WORKHABIT_ENDPOINT,
  DELETE_OBJECTIVE_ENDPOINT,
  HABITS_REDUX,
  SELECT_GSNI_REDUX,
  SELECT_STUDENT_WORKHABIT_REDUX,
  SELECT_STUDENT_GRADEBOOK_REDUX,
  CREATE_WORKHABIT_REDUX,
  CREATE_OBJECTIVE_REDUX,
  UPDATE_STUDENT_GRADEBOOK_REDUX,
  UPDATE_STUDENT_WORKHABIT_REDUX,
  UPDATE_WORKHABIT_REDUX,
  UPDATE_OBJECTIVE_REDUX,
  DELETE_WORKHABIT_REDUX,
  DELETE_OBJECTIVE_REDUX,
};
