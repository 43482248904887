import { createSlice } from '@reduxjs/toolkit';

import { SIGN_REDUX } from './SignUpConstants';
import signUpAction from './SignUpActions';

const initialState = {
  signLoading: false,
};

const signSlice = createSlice({
  name: SIGN_REDUX,
  initialState,
  reducers: {
    setSignFetching: (state, payload) => {
      state.signLoading = payload;
    },
  },
  extraReducers: {
    [signUpAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.signLoading = true;
    },
    [signUpAction.fulfilled.type]: (state) => {
      state.signLoading = false;
    },
    [signUpAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.signLoading = false;
    },
  },
});

export const { setSignFetching } = signSlice.actions;
export default signSlice.reducer;
