import { createSlice } from '@reduxjs/toolkit';

import { SCALES_REDUX } from './ScalesConstants';
import selectScalesAction from './ScalesActions';

const initialState = {
  scalesUpdating: false,
};

const scalesSlice = createSlice({
  name: SCALES_REDUX,
  initialState,
  reducers: {
    setScalesUpdating: (state, payload) => {
      state.scalesUpdating = payload;
    },
  },
  extraReducers: {
    [selectScalesAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectScalesAction.fulfilled.type]: (state) => {
      state.scalesUpdating = false;
    },
    [selectScalesAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.scalesUpdating = false;
    },
  },
});

export const { setScalesUpdating } = scalesSlice.actions;
export default scalesSlice.reducer;
