import { Box, List, styled } from '@mui/material';
import Flexbox from 'UI/Flexbox/Flexbox';

import themePalette from 'theme/Palette';

const SidebarWrapper = styled(Box)(({ theme }) => ({
  width: 300,
  height: '100vh',
  position: 'fixed',
  padding: '35px 15px 50px',
  left: 0,
  zIndex: theme.zIndex.drawer + 11,
  transition: 'all 0.3s',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    left: -80,
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 7,
  },
  '& .simplebar-scrollbar:before': {
    background: theme.palette.text.primary,
  },
}));

const LogoWrapper = styled(Box)(() => ({
  padding: '0px 0px 45px 35px',
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const LogoHeader = styled('h1')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  lineHeight: '1',
  margin: '0',
  marginTop: '5px',
}));

const ListRoot = styled(List)(() => ({
  height: '100%',
  paddingTop: '15px',
}));

const MenuItem = styled(Flexbox)(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontWeight: 500,
  fontSize: '15px',
  width: '100%',
  padding: '10px 35px',
  cursor: 'pointer',
  borderRadius: '10px',
  lineHeight: '1 !important',
  marginBottom: '10px',
  backgroundColor: active ? themePalette.primaryMuted : 'transparent',
  color: active ? themePalette.primary : 'inherit',
  transition: 'all .3s',
  p: {
    margin: '0px',
  },
  '&:hover': {
    backgroundColor: themePalette.primaryMuted,
    color: themePalette.primary,
  },
}));

export { LogoWrapper, ListRoot, LogoHeader, MenuItem };
export default SidebarWrapper;
