import { createSlice } from '@reduxjs/toolkit';

import { COMMENTS_REDUX } from './CommentsConstants';

import selectCommentsAction from './CommentsActions';

const initialState = {
  commentsUpdating: false,
  storageComment: null,
  storageWorkhabit: null,
  storageEngagement: null,
};

const commentsSlice = createSlice({
  name: COMMENTS_REDUX,
  initialState,
  reducers: {
    setCommentsUpdating: (state, payload) => {
      state.commentsUpdating = payload;
    },
    setStorageComment: (state, parameter) => {
      state.storageComment = parameter.payload;
    },
    setStorageWorkhabit: (state, parameter) => {
      state.storageWorkhabit = parameter.payload;
    },
    setStorageEngagement: (state, parameter) => {
      state.storageEngagement = parameter.payload;
    },
  },
  extraReducers: {
    [selectCommentsAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectCommentsAction.fulfilled.type]: (state) => {
      state.commentsUpdating = false;
    },
    [selectCommentsAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.commentsUpdating = false;
    },
  },
});

export const {
  setCommentsUpdating,
  setStorageComment,
  setStorageWorkhabit,
  setStorageEngagement,
} = commentsSlice.actions;
export default commentsSlice.reducer;
