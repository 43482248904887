import { styled, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import themePalette from 'theme/Palette';

const BaseButtonRoot = styled(Button)(() => ({
  fontFamily: 'Roboto',
  backgroundColor: themePalette.primary,
  padding: '10px 16px',
  fontSize: '14px',
  textTransform: 'none',
  borderRadius: '0.7rem',
  color: themePalette.white,
  letterSpacing: '1px',
  textDecoration: 'none',
  fontWeight: 400,
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: themePalette.primary,
    color: themePalette.white,
    opacity: '0.85',
  },
}));

const SmallBaseButtonRoot = styled(Button)(() => ({
  fontFamily: 'Roboto',
  backgroundColor: themePalette.primary,
  padding: '8px 20px',
  fontSize: '13px',
  borderRadius: '0.7rem',
  color: themePalette.white,
  textDecoration: 'none',
  textTransform: 'none',
  fontWeight: 400,
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: themePalette.primary,
    color: themePalette.white,
    opacity: '0.85',
  },
}));

const LoadingButtonRoot = styled(LoadingButton)(() => ({
  backgroundColor: themePalette.primary,
  padding: '10px 16px',
  fontSize: '14px',
  textTransform: 'none',
  borderRadius: '0.7rem',
  letterSpacing: '1px',
  color: themePalette.white,
  textDecoration: 'none',
  fontWeight: 400,
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: themePalette.primary,
    color: themePalette.white,
    opacity: '0.85',
  },
  svg: {
    color: themePalette.white,
  },
}));

export { LoadingButtonRoot, SmallBaseButtonRoot };
export default BaseButtonRoot;
