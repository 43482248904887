import { createSlice } from '@reduxjs/toolkit';

import { STUDENTS_REDUX } from './StudentsConstants';
import selectStudentsAction from './StudentsActions';

const initialState = {
  studentsUpdating: false,
};

const studentsSlice = createSlice({
  name: STUDENTS_REDUX,
  initialState,
  reducers: {
    setStudentsUpdating: (state, payload) => {
      state.studentsUpdating = payload;
    },
  },
  extraReducers: {
    [selectStudentsAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectStudentsAction.fulfilled.type]: (state) => {
      state.studentsUpdating = false;
    },
    [selectStudentsAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.studentsUpdating = false;
    },
  },
});

export const { setStudentsUpdating } = studentsSlice.actions;
export default studentsSlice.reducer;
