import { useNavigate } from 'react-router';
import React from 'react';

import LandingHeader, {
  ContainerHeader,
  HeaderLink,
  HeaderSignUpLink,
} from './styled';
import Flexbox from 'UI/Flexbox/Flexbox';

import {
  COMMING_SOON_PATH,
  CONTACT_US_PATH,
  HOME_PATH,
  LANDING_PATH,
  LOGIN_PATH,
  SIGN_PATH,
  SOON_PATH,
} from 'routes/path';

import Logo from 'assets/components/Logo';

const LandingNavbar = ({ auth }) => {
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    window.location.href = LOGIN_PATH;
  };

  const handleContactUsNavigate = () => {
    window.location.href = CONTACT_US_PATH;
  };

  const handleBlogNavigate = () => {
    window.open('https://e2grade.info/blog/', '_target');
  };

  const handleTripTricksNavigate = () => {
    window.open('https://e2grade.info/tip/', '_target');
  };

  const handleProgramGuideNavigate = () => {
    window.open('https://e2grade.info/program-guide/', '_target');
  };

  const handleFaqNavigate = () => {
    window.open('https://e2grade.info/faq/', '_target');
  };

  const handleSignUpNavigate = () => {
    window.location.href = SIGN_PATH;
  };

  const handleHomeNavigate = () => {
    window.location.href = LANDING_PATH;
  };

  const handleDashboardNavigate = () => {
    navigate(HOME_PATH);
  };

  const renderMenuLinks = (
    <Flexbox gap="25px" alignItems="center" sx={{ marginLeft: '50px' }}>
      <HeaderLink onClick={handleBlogNavigate}>Blog</HeaderLink>
      <HeaderLink onClick={handleProgramGuideNavigate}>
        Program Guide
      </HeaderLink>
      <HeaderLink onClick={handleTripTricksNavigate}>
        Tips and Tricks
      </HeaderLink>
      <HeaderLink onClick={handleFaqNavigate}>FAQ</HeaderLink>
      <HeaderLink onClick={handleContactUsNavigate}>Contact Us</HeaderLink>
    </Flexbox>
  );

  const renderDashboardLinks = (
    <Flexbox gap="25px" alignItems="center" sx={{ marginLeft: 'auto' }}>
      <HeaderLink onClick={handleDashboardNavigate}>Dashboard</HeaderLink>
    </Flexbox>
  );

  const renderAuthLinks = (
    <Flexbox gap="25px" alignItems="center" sx={{ marginLeft: 'auto' }}>
      <HeaderLink onClick={handleLoginNavigate}>Sign In</HeaderLink>
      <HeaderSignUpLink onClick={handleSignUpNavigate}>
        Sign Up
      </HeaderSignUpLink>
    </Flexbox>
  );

  const renderLogo = auth ? (
    <div>
      <Logo width={60} height={60} />
    </div>
  ) : (
    <div onClick={handleHomeNavigate} style={{ cursor: 'pointer' }}>
      <Logo width={60} height={60} />
    </div>
  );

  return (
    <LandingHeader>
      <ContainerHeader>
        <Flexbox alignItems="center">
          {renderLogo}
          {!auth && renderMenuLinks}
          {auth ? renderDashboardLinks : renderAuthLinks}
        </Flexbox>
      </ContainerHeader>
    </LandingHeader>
  );
};

export default LandingNavbar;
