import ToasterProvider from 'contexts/ToasterProvider';
import React, { Fragment, Suspense } from 'react';
import { useRoutes } from 'react-router';

import routes from 'routes/router';

const App = () => {
  const pages = useRoutes(routes);

  return (
    <Fragment>
      <ToasterProvider />
      <Suspense fallback={<div>Loading...</div>}>{pages}</Suspense>
    </Fragment>
  );
};

export default App;
