/* eslint-disable react-hooks/exhaustive-deps */
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Stack } from '@mui/material';
import React from 'react';

import LinkRoot from './styled';

import DICTIONARY from 'core/Dictionary';

import { getPathnames } from 'utils/helper';

const DashboardBreadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = getPathnames(location.pathname);

  const lastIndex = pathnames.length - 1;

  const onReturnClick = (event) => {
    event.preventDefault();
    const history = localStorage.getItem(DICTIONARY.historyPath);
    navigate(history);
  };

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {pathnames.map((path, index) => (
          <LinkRoot
            href={path}
            active={path === pathnames[lastIndex] ? 1 : 0}
            onClick={path !== pathnames[lastIndex] ? onReturnClick : null}
            key={index + path}
          >
            {path}
          </LinkRoot>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default DashboardBreadcrumbs;
