import { createSlice } from '@reduxjs/toolkit';

import { CATEGORIES_REDUX } from './CategoriesConstants';
import selectCategoriesAction from './CategoriesActions';

const initialState = {
  categoriesUpdating: false,
  habitsUpdating: false,
};

const categoriesSlice = createSlice({
  name: CATEGORIES_REDUX,
  initialState,
  reducers: {
    setCategoriesUpdating: (state, payload) => {
      state.categoriesUpdating = payload;
    },
    setHabitsUpdating: (state, payload) => {
      state.categoriesUpdating = payload;
    },
  },
  extraReducers: {
    [selectCategoriesAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectCategoriesAction.fulfilled.type]: (state) => {
      state.categoriesUpdating = false;
    },
    [selectCategoriesAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.categoriesUpdating = false;
    },
  },
});

export const { setCategoriesUpdating, setHabitsUpdating } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;
