import { createSlice } from '@reduxjs/toolkit';

import { HABITS_REDUX } from './WorkHabitsConstants';
import selectHabitsAction from './WorkHabitsActions';

const initialState = {
  habitsUpdating: false,
};

const habitsSlice = createSlice({
  name: HABITS_REDUX,
  initialState,
  reducers: {
    setHabitsFetching: (state, payload) => {
      state.habitsUpdating = payload;
    },
  },
  extraReducers: {
    [selectHabitsAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.habitsUpdating = true;
    },
    [selectHabitsAction.fulfilled.type]: (state) => {
      state.habitsUpdating = false;
    },
    [selectHabitsAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.habitsUpdating = false;
    },
  },
});

export const { setHabitsFetching } = habitsSlice.actions;
export default habitsSlice.reducer;
