import { get_request, post_request } from 'api/Requests.js';
import DICTIONARY from 'core/Dictionary.js';

import {
  GET_USER_ENDPOINT,
  GET_USER_REDUX,
  LOGIN_CREATE_REDUX,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  LOGOUT_REDUX,
} from './LoginConstants.js';

const loginAction = post_request(
  LOGIN_CREATE_REDUX,
  LOGIN_ENDPOINT,
  DICTIONARY.pureRequest,
);

const getUserAction = get_request(
  GET_USER_REDUX,
  GET_USER_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const logoutAction = post_request(
  LOGOUT_REDUX,
  LOGOUT_ENDPOINT,
  DICTIONARY.pureRequest,
);

export { getUserAction, logoutAction };
export default loginAction;
