import { createSlice } from '@reduxjs/toolkit';

import { ARCHIVE_REDUX } from './ArchiveConstants';
import selectArchiveAction from './ArchiveActions';

const initialState = {
  archiveUpdating: false,
};

const archiveSlice = createSlice({
  name: ARCHIVE_REDUX,
  initialState,
  reducers: {
    setArchiveUpdating: (state, payload) => {
      state.archiveUpdating = payload;
    },
  },
  extraReducers: {
    [selectArchiveAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectArchiveAction.fulfilled.type]: (state) => {
      state.archiveUpdating = false;
    },
    [selectArchiveAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.archiveUpdating = false;
    },
  },
});

export const { setArchiveUpdating } = archiveSlice.actions;
export default archiveSlice.reducer;
