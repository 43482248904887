const PRE_CLASS_ENDPOINT = '/school/gradebook';
const PRE_COMMENTS_ENDPOINT = '/school/comment';
const PRE_WORKHABIT_ENDPOINT = '/school/workhabit-comment';
const PRE_ENGAGEMENT_ENDPOINT = '/school/engagement-comment';
const PRE_PURE_WORKHABIT_ENDPOINT = '/school/workhabit';
const PRE_PURE_ENDGAGEMENT_ENDPOINT = '/school/engagement';

const SELECT_COMMENTS_ENDPOINT = '/comments/info';
const CHANGE_COMMENTS_ENDPOINT = '/change';
const SELECT_WORKHABIT_ENDPOINT = '/workhabit-comments/info';
const CHANGE_WORKHABIT_ENDPOINT = '/change';
const SELECT_ENGAGEMENT_ENDPOINT = '/engagement-comments/info';
const CHANGE_ENGAGEMENT_ENDPOINT = '/change';
const GENERATE_AI_ENDPOINT = '/ai-comment-generate';
const GENERATE_AI_WORKHABIT_ENDPOINT = '/ai-comment-generate';
const GENERATE_AI_ENGAGEMENT_ENDPOINT = '/ai-comment-generate';

const COMMENTS_REDUX = 'comments';
const SELECT_COMMENTS_REDUX = 'comments/select';
const CHANGE_COMMENTS_REDUX = 'comments/change';
const SELECT_WORKHABIT_REDUX = 'comments/workhabitSelect';
const CHANGE_WORKHABIT_REDUX = 'comments/workhabitChange';
const SELECT_ENGAGEMENT_REDUX = 'comments/engagementSelect';
const CHANGE_ENGAGEMENT_REDUX = 'comments/engagementChange';
const GENERATE_AI_REDUX = 'comments/generateAI';
const GENERATE_AI_WORKHABIT_REDUX = 'comments/generateAIWorkhabit';
const GENERATE_AI_ENGAGEMENT_REDUX = 'comments/generateAIEngagement';

export {
  PRE_CLASS_ENDPOINT,
  PRE_COMMENTS_ENDPOINT,
  PRE_WORKHABIT_ENDPOINT,
  PRE_ENGAGEMENT_ENDPOINT,
  PRE_PURE_ENDGAGEMENT_ENDPOINT,
  PRE_PURE_WORKHABIT_ENDPOINT,
  SELECT_COMMENTS_ENDPOINT,
  SELECT_WORKHABIT_ENDPOINT,
  SELECT_ENGAGEMENT_ENDPOINT,
  CHANGE_COMMENTS_ENDPOINT,
  CHANGE_WORKHABIT_ENDPOINT,
  CHANGE_ENGAGEMENT_ENDPOINT,
  GENERATE_AI_ENDPOINT,
  GENERATE_AI_WORKHABIT_ENDPOINT,
  GENERATE_AI_ENGAGEMENT_ENDPOINT,
  COMMENTS_REDUX,
  SELECT_COMMENTS_REDUX,
  SELECT_WORKHABIT_REDUX,
  SELECT_ENGAGEMENT_REDUX,
  CHANGE_COMMENTS_REDUX,
  CHANGE_WORKHABIT_REDUX,
  CHANGE_ENGAGEMENT_REDUX,
  GENERATE_AI_REDUX,
  GENERATE_AI_WORKHABIT_REDUX,
  GENERATE_AI_ENGAGEMENT_REDUX,
};
