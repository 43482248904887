import { createSlice } from '@reduxjs/toolkit';

import { REPORT_REDUX } from './ReportsConstants';

import selectReportAction from './ReportsActions';

const initialState = {
  reportUpdating: false,
};

const reportsSlice = createSlice({
  name: REPORT_REDUX,
  initialState,
  reducers: {
    setReportsUpdating: (state, payload) => {
      state.reportUpdating = payload;
    },
  },
  extraReducers: {
    [selectReportAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectReportAction.fulfilled.type]: (state) => {
      state.reportUpdating = false;
    },
    [selectReportAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.reportUpdating = false;
    },
  },
});

export const { setReportsUpdating } = reportsSlice.actions;
export default reportsSlice.reducer;
