import React from 'react';

import { Paragraph } from 'UI/Typography';
import Flexbox from 'UI/Flexbox/Flexbox';

import commingSoon from 'assets/icons/commingSoon.png';
import BaseButton from 'UI/BaseButton/BaseButton';
import { useNavigate } from 'react-router';
import { LOGIN_PATH } from 'routes/path';

const CommingSoon = ({ returnable }) => {
  const navigate = useNavigate();
  const handleReturn = () => {
    navigate(LOGIN_PATH);
  };

  return (
    <Flexbox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: 'calc(90vh - 122px)' }}
    >
      <Flexbox maxWidth="400px">
        <img
          src={commingSoon}
          alt="comming-soon"
          style={{ maxWidth: '100%' }}
        />
      </Flexbox>
      <Paragraph
        text="We are currently working hard on this page!"
        fontSize="16px"
        fontWeight="400"
        sx={{ color: 'rgb(99, 115, 129)', letterSpacing: '0.5px' }}
      />
      {returnable && (
        <BaseButton
          label="Return back"
          onClick={handleReturn}
          overrideStyles={{ marginTop: '20px' }}
        />
      )}
    </Flexbox>
  );
};

export default CommingSoon;
