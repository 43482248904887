import { Box } from '@mui/material';
import React from 'react';

const H4 = ({ text, overrideStyles, ...props }) => {
  return (
    <Box
      component="h4"
      mb={0}
      mt={0}
      fontSize="20px"
      fontWeight="700"
      lineHeight="1.5"
      sx={overrideStyles}
      {...props}
    >
      {text}
    </Box>
  );
};

export default H4;
