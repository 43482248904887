import { get_request, put_request } from 'api/Requests.js';

import {
  ARCHIVE_SELECT_ENDPOINT,
  ARCHIVE_SELECT_REDUX,
  PRE_ARCHIVE_ENDPOINT,
  UNARCHIVE_REDUX,
} from './ArchiveConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectArchiveAction = get_request(
  ARCHIVE_SELECT_REDUX,
  ARCHIVE_SELECT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const unarchiveAction = put_request(
  UNARCHIVE_REDUX,
  PRE_ARCHIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export { unarchiveAction };
export default selectArchiveAction;
