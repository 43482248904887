const PRE_CLASS_ENDPOINT = '/school/class';
const PRE_SCALE_ENDPOINT = '/school/grade';
const PRE_PROFICIENCY_ENDPOINT = '/school/proficiency';

const SELECT_PROFICIENCY_ENDPOINT = '/proficiencies/info';
const UPDATE_PROFICIENCY_ENDPOINT = '/change';

const SCALES_REDUX = 'scales';
const SCALES_SELECT_REDUX = 'scales/info';
const PROFICIENCY_UPDATE_REDUX = 'scales/changeProficiency';
const PROFICIENCY_NAME_CHANGE_REDUX = 'grade/changeProficiencyName';

export {
  PRE_CLASS_ENDPOINT,
  PRE_SCALE_ENDPOINT,
  PRE_PROFICIENCY_ENDPOINT,
  SELECT_PROFICIENCY_ENDPOINT,
  SCALES_REDUX,
  SCALES_SELECT_REDUX,
  PROFICIENCY_UPDATE_REDUX,
  UPDATE_PROFICIENCY_ENDPOINT,
  PROFICIENCY_NAME_CHANGE_REDUX,
};
