import React from 'react';

import BaseButtonRoot, {
  LoadingButtonRoot,
  SmallBaseButtonRoot,
} from './styled';

const BUTTON_SIZE = {
  sm: 'sm',
  lg: 'lg',
};

const BaseButton = ({
  label,
  className,
  onClick,
  fullWidth,
  disabled,
  isLoading,
  size = BUTTON_SIZE.lg,
  overrideStyles,
  children,
}) => {
  const renderButton =
    size === BUTTON_SIZE.lg ? (
      <BaseButtonRoot
        className={className}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={disabled}
        sx={overrideStyles}
      >
        {children}
        {label}
      </BaseButtonRoot>
    ) : (
      <SmallBaseButtonRoot
        className={className}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={disabled}
        sx={overrideStyles}
      >
        {children}
        {label}
      </SmallBaseButtonRoot>
    );

  return isLoading ? (
    <LoadingButtonRoot
      className={className}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
      sx={overrideStyles}
      loading
    >
      {label}
    </LoadingButtonRoot>
  ) : (
    renderButton
  );
};

export default BaseButton;
