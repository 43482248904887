import { Box } from '@mui/material';
import React from 'react';

import themePalette from 'theme/Palette';

const H2 = ({ text, overrideStyles, ...props }) => {
  return (
    <Box
      component="h4"
      mb={0}
      mt={0}
      color={themePalette.textPrimary}
      fontSize="34px"
      fontWeight="700"
      lineHeight="1.5"
      sx={overrideStyles}
      {...props}
    >
      {text}
    </Box>
  );
};

export default H2;
