import { createSlice } from '@reduxjs/toolkit';

import { NEW_PASSWORD_REDUX } from './NewPasswordConstants';
import newPasswordAction from './NewPasswordActions';

const initialState = {
  newPasswordLoading: false,
};

const newPasswordSlice = createSlice({
  name: NEW_PASSWORD_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [newPasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.newPasswordLoading = true;
    },
    [newPasswordAction.fulfilled.type]: (state) => {
      state.newPasswordLoading = false;
    },
    [newPasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.newPasswordLoading = false;
    },
  },
});

export default newPasswordSlice.reducer;
