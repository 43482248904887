// Pre urls
const PRE_GRADEBOOK_ENDPOINT = '/school/gradebook';
const PRE_CATEGORIES_ENDPOINT = '/school/assessment-category';
const PRE_CATEGORIES_OBJECTIVE_ENDPOINT =
  '/school/assessment-category/objective';

// Categories Urls
const SELECT_CATEGORIES_ENDPOINT = '/assessment-category/info';
const CREATE_CATEGORIES_ENDPOINT = '/school/assessment-category/create';
const UPDATE_CATEGORIES_ENDPOINT = '/change';
const DELETE_CATEGORIES_ENDPOINT = '/delete';
const VALIDATE_CATEGORIES_ENDPOINT = '/assessment-category/weight/validate';

// Habits Urls
const SELECT_HABITS_ENDPOINT = '/assessment-category/info';
const CREATE_HABITS_ENDPOINT = '/school/assessment-category/create';
const UPDATE_HABITS_ENDPOINT = '/change';
const DELETE_HABITS_ENDPOINT = '/delete';

// Objectives Urls
const CREATE_OBJECTIVE_ENDPOINT =
  '/school/assessment-category/objective/create';
const UPDATE_OBJECTIVE_ENDPOINT = '/change';
const DELETE_OBJECTIVE_ENDPOINT = '/delete';

// Redux
const CATEGORIES_REDUX = 'categories';
const CATEGORIES_SELECT_REDUX = 'categories/info';
const CATEGORIES_UPDATE_REDUX = 'categories/changeCategories';
const CATEGORIES_CREATE_REDUX = 'categories/createCategories';
const CATEGORIES_DELETE_REDUX = 'categories/deleteCategories';
const CATEGORIES_VALIDATE_REDUX = 'categories/validateCategories';
const HABITS_SELECT_REDUX = 'categories/habitsInfo';
const HABITS_UPDATE_REDUX = 'categories/changeHabits';
const HABITS_CREATE_REDUX = 'categories/createHabits';
const HABITS_DELETE_REDUX = 'categories/deleteHabits';
const CREATE_OBJECTIVE_REDUX = 'categories/createObjective';
const UPDATE_OBJECTIVE_REDUX = 'categories/updateObjective';
const DELETE_OBJECTIVE_REDUX = 'categories/deleteObjective';

export {
  PRE_GRADEBOOK_ENDPOINT,
  PRE_CATEGORIES_ENDPOINT,
  PRE_CATEGORIES_OBJECTIVE_ENDPOINT,
  SELECT_CATEGORIES_ENDPOINT,
  CREATE_CATEGORIES_ENDPOINT,
  UPDATE_CATEGORIES_ENDPOINT,
  DELETE_CATEGORIES_ENDPOINT,
  SELECT_HABITS_ENDPOINT,
  CREATE_HABITS_ENDPOINT,
  UPDATE_HABITS_ENDPOINT,
  DELETE_HABITS_ENDPOINT,
  CREATE_OBJECTIVE_ENDPOINT,
  UPDATE_OBJECTIVE_ENDPOINT,
  DELETE_OBJECTIVE_ENDPOINT,
  VALIDATE_CATEGORIES_ENDPOINT,
  CATEGORIES_REDUX,
  CATEGORIES_SELECT_REDUX,
  CATEGORIES_UPDATE_REDUX,
  CATEGORIES_CREATE_REDUX,
  CATEGORIES_VALIDATE_REDUX,
  CATEGORIES_DELETE_REDUX,
  HABITS_CREATE_REDUX,
  HABITS_UPDATE_REDUX,
  HABITS_SELECT_REDUX,
  HABITS_DELETE_REDUX,
  CREATE_OBJECTIVE_REDUX,
  UPDATE_OBJECTIVE_REDUX,
  DELETE_OBJECTIVE_REDUX,
};
