import {
  delete_request,
  get_request,
  patch_request,
  post_request,
  put_request,
} from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  CATEGORIES_CREATE_REDUX,
  CATEGORIES_DELETE_REDUX,
  CATEGORIES_SELECT_REDUX,
  CATEGORIES_UPDATE_REDUX,
  CATEGORIES_VALIDATE_REDUX,
  CREATE_CATEGORIES_ENDPOINT,
  CREATE_OBJECTIVE_ENDPOINT,
  CREATE_OBJECTIVE_REDUX,
  DELETE_OBJECTIVE_REDUX,
  PRE_CATEGORIES_ENDPOINT,
  PRE_CATEGORIES_OBJECTIVE_ENDPOINT,
  PRE_GRADEBOOK_ENDPOINT,
  UPDATE_OBJECTIVE_REDUX,
} from './CategoriesConstants.js';

// Categories
const selectCategoriesAction = get_request(
  CATEGORIES_SELECT_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createCategoriesAction = post_request(
  CATEGORIES_CREATE_REDUX,
  CREATE_CATEGORIES_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createCategoriesObjectiveAction = post_request(
  CREATE_OBJECTIVE_REDUX,
  CREATE_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateCategoriesAction = put_request(
  CATEGORIES_UPDATE_REDUX,
  PRE_CATEGORIES_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateCategoriesObjectiveAction = patch_request(
  UPDATE_OBJECTIVE_REDUX,
  PRE_CATEGORIES_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const validateCategoriesAction = get_request(
  CATEGORIES_VALIDATE_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteCategoriesAction = delete_request(
  CATEGORIES_DELETE_REDUX,
  PRE_CATEGORIES_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteCategoriesObjectiveAction = delete_request(
  DELETE_OBJECTIVE_REDUX,
  PRE_CATEGORIES_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  createCategoriesAction,
  createCategoriesObjectiveAction,
  updateCategoriesObjectiveAction,
  updateCategoriesAction,
  deleteCategoriesAction,
  deleteCategoriesObjectiveAction,
  validateCategoriesAction,
};
export default selectCategoriesAction;
