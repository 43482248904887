import { createSlice } from '@reduxjs/toolkit';

import { LOGIN_REDUX } from './LoginConstants';
import loginAction from './LoginActions';

const initialState = {
  loginLoading: false,
};

const loginSlice = createSlice({
  name: LOGIN_REDUX,
  initialState,
  reducers: {
    setLoginFetching: (state, payload) => {
      state.signLoading = payload;
    },
  },
  extraReducers: {
    [loginAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.loginLoading = true;
    },
    [loginAction.fulfilled.type]: (state) => {
      state.loginLoading = false;
    },
    [loginAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.loginLoading = false;
    },
  },
});

export const { setLoginFetching } = loginSlice.actions;
export default loginSlice.reducer;
