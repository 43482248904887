import jwt_decode from 'jwt-decode';

const isFillObject = (object) => {
  return Object.keys(object).length > 0;
};

const jwtDecoder = (accessToken, refreshToken) => {
  const tokenValidator = {
    isValidAccess: true,
    isValidRefresh: true,
  };

  const isAccessNull = accessToken === null,
    isRefreshNull = refreshToken === null;

  if (!accessToken) tokenValidator.isValidAccess = false;
  if (!refreshToken) tokenValidator.isValidRefresh = false;

  if (accessToken && refreshToken) {
    const decodeAccess = jwt_decode(accessToken),
      decodeRefresh = jwt_decode(refreshToken);

    const currentDate = new Date();

    if (!decodeAccess || decodeAccess.exp * 1000 < currentDate.getTime())
      tokenValidator.isValidAccess = false;

    if (!decodeRefresh || decodeRefresh.exp * 1000 < currentDate.getTime())
      tokenValidator.isValidRefresh = false;
  }

  if (isAccessNull) tokenValidator.isValidAccess = false;
  if (isRefreshNull) tokenValidator.isValidRefresh = false;

  return tokenValidator;
};

const getPathnames = (pathnames) => {
  const pathnamesArray = pathnames
    .split('/')
    .filter((path) => path.length < 30 && path);

  if (pathnamesArray[1] && pathnamesArray[1].indexOf('&') > -1) {
    const newString = pathnamesArray[1].split('&').join(' & ');
    pathnamesArray[1] = newString;
  }

  return pathnamesArray;
};

const parseServerError = (error) => {
  const serverError = {
    stringError: null,
    objectError: null,
  };

  const response = error?.response?.data;
  const currentError = response?.error;
  const detail = currentError?.detail;

  if (typeof detail === 'string' || detail instanceof String)
    serverError.stringError = detail;
  else serverError.objectError = detail;

  return serverError;
};

const addZeroes = (num) => {
  return num.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits: 1,
  });
};

const getGradebooksIDFromPillars = (array) => {
  if (array.length === 0) return [];

  const state = [];

  array.forEach((item) => {
    const gradebookId = item?.gradebook?.id;
    state.push(gradebookId);
  });

  return state;
};

const addIndexToCategories = (data) => {
  const state = [...data];
  const newData = state.map((item, index) => {
    return { ...item, pillarIndex: index };
  });

  return newData;
};

const timeoutFunction = (hanlder, timeout) => {
  return setTimeout(() => hanlder(), timeout);
};

const getProficiencyNames = (data) => {
  const obj = {};

  data.forEach((item) => {
    const key = item.name;
    obj[key] = item.name;
  });

  return obj;
};

const checkParameters = (parameter) => {
  return parameter || parameter === 0;
};

export {
  isFillObject,
  getGradebooksIDFromPillars,
  jwtDecoder,
  getPathnames,
  parseServerError,
  addZeroes,
  addIndexToCategories,
  timeoutFunction,
  checkParameters,
  getProficiencyNames,
};
