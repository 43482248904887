import {
  delete_request,
  get_request,
  patch_request,
  post_request,
} from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  CREATE_OBJECTIVE_ENDPOINT,
  CREATE_OBJECTIVE_REDUX,
  CREATE_WORKHABIT_ENDPOINT,
  CREATE_WORKHABIT_REDUX,
  DELETE_OBJECTIVE_REDUX,
  DELETE_WORKHABIT_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  PRE_OBJECTIVE_ENDPOINT,
  PRE_STUDENT_GRADEBOOK_ENDPOINT,
  PRE_STUDENT_WORKHABIT_ENDPOINT,
  PRE_WORKHABIT_ENDPOINT,
  SELECT_GSNI_ENDPOINT,
  SELECT_GSNI_REDUX,
  SELECT_STUDENT_GRADEBOOK_REDUX,
  SELECT_STUDENT_WORKHABIT_REDUX,
  UPDATE_OBJECTIVE_REDUX,
  UPDATE_STUDENT_GRADEBOOK_REDUX,
  UPDATE_STUDENT_WORKHABIT_REDUX,
  UPDATE_WORKHABIT_REDUX,
} from './WorkHabitsConstants.js';

const selectGSNIAction = get_request(
  SELECT_GSNI_REDUX,
  SELECT_GSNI_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectStudentsWorkHabitsAction = get_request(
  SELECT_STUDENT_WORKHABIT_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectStudentsGradebooksAction = get_request(
  SELECT_STUDENT_GRADEBOOK_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createWorkhabitAction = post_request(
  CREATE_WORKHABIT_REDUX,
  CREATE_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createObjectiveAction = post_request(
  CREATE_OBJECTIVE_REDUX,
  CREATE_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateStudentsGradebookAction = patch_request(
  UPDATE_STUDENT_GRADEBOOK_REDUX,
  PRE_STUDENT_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateWorkhabitAction = patch_request(
  UPDATE_WORKHABIT_REDUX,
  PRE_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateObjectiveAction = patch_request(
  UPDATE_OBJECTIVE_REDUX,
  PRE_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const updateStudentWorkhabitAction = patch_request(
  UPDATE_STUDENT_WORKHABIT_REDUX,
  PRE_STUDENT_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteWorkhabitAction = delete_request(
  DELETE_WORKHABIT_REDUX,
  PRE_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteObjectiveAction = delete_request(
  DELETE_OBJECTIVE_REDUX,
  PRE_OBJECTIVE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  selectGSNIAction,
  selectStudentsGradebooksAction,
  createWorkhabitAction,
  createObjectiveAction,
  updateStudentsGradebookAction,
  updateWorkhabitAction,
  updateObjectiveAction,
  updateStudentWorkhabitAction,
  deleteWorkhabitAction,
  deleteObjectiveAction,
};
export default selectStudentsWorkHabitsAction;
