import { get_request, patch_request, post_request } from 'api/Requests.js';

import {
  CHANGE_REPORT_REDUX,
  CREATE_REPORT_REDUX,
  PRE_GRADEBOOK_REPORT_ENDPOINT,
  PRE_REPORT_ENDPOINT,
  SELECT_REPORT_REDUX,
} from './ReportsConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectReportAction = get_request(
  SELECT_REPORT_REDUX,
  PRE_GRADEBOOK_REPORT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeReportAction = patch_request(
  CHANGE_REPORT_REDUX,
  PRE_REPORT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createReportAction = post_request(
  CREATE_REPORT_REDUX,
  PRE_GRADEBOOK_REPORT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export { changeReportAction, createReportAction };
export default selectReportAction;
