const themePalette = {
  white: '#fff',
  primary: 'rgb(45, 139, 171)',
  primaryMuted: 'rgba(45, 139, 171, 0.10)',
  border: 'rgba(0, 0, 0, 0.12)',
  textPrimary: '#1b1b1b',
  textMuted: '#67758b',
  textPrimaryMuted: '#5c697b',
  iconMuted: 'rgba(0, 0, 0, 0.40)',
  boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
  red: 'rgba(182, 66, 66, 0.8)',
  tableHeader: 'rgb(244, 246, 248)',
  totalTableCell: 'rgba(100, 100, 100, 0.35)',
  tableHeaderMuted: 'rgba(244, 246, 248, 1)',
  tableHeaderText: 'rgba(51, 51, 53, 0.85)',
  boxShadowPopover:
    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.05), 0px 3px 14px 2px rgba(0,0,0,0.05)',
  boxShadowContext: '0 2px 6px 2px rgba(60,64,67,.15)',
  cellActive: 'rgb(144, 202, 249)',
};

const profColors = [
  'rgb(55, 111, 208)',
  'rgb(255, 152, 0)',
  'rgb(67, 160, 71)',
  'rgb(244, 67, 54)',
];

const tagColors = [
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
  'rgba(26,193,215,0.52)',
  'rgba(101,203,173,0.73)',
  'rgba(144,208,150,0.72)',
  'rgba(203,215,118,0.65)',
  'rgba(240,220,97,0.54)',
  'rgba(175,218,200,0.9)',
  'rgba(46,153,166,0.51)',
  'rgba(192,77,185,0.3)',
  'rgba(220,77,77,0.46)',
];

export { tagColors, profColors };
export default themePalette;
