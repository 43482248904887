export const PRE_GRADEBOOK_REPORT_ENDPOINT = '/school/gradebook';
export const MID_REPORT_ENDPOINT = '/student-gradebook/';
export const PRE_REPORT_ENDPOINT = '/school/report';

export const SELECT_REPORT_ENDPOINT = '/report/info';
export const CHANGE_REPORT_ENDPOINT = '/change';
export const CREATE_REPORT_ENDPOINT = '/report/create';

export const REPORT_REDUX = 'report';
export const SELECT_REPORT_REDUX = 'report/select';
export const CHANGE_REPORT_REDUX = 'report/change';
export const CREATE_REPORT_REDUX = 'report/create';
