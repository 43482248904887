const PRE_GRADEBOOKS_ENDPOINT = '/school/class';
const SELECT_GRADEBOOKS_ENDPOINT = '/gradebooks/info';

const PRE_GRADEBOOK_ENDPOINT = '/school/gradebook';
const SELECT_GRADEBOOK_ENDPOINT = '/info';
const CREATE_GRADEBOOK_ENDPOINT = PRE_GRADEBOOK_ENDPOINT + '/create';
const CHANGE_GRADEBOOK_ENDPOINT = '/change';
const DELETE_GRADEBOOK_ENDPOINT = '/delete';

const PRE_COLUMN_ENDPOINT = '/school/gradebook/column';
const CREATE_COLUMN_ENDPOINT = '/school/gradebook/column/create';
const CHANGE_COLUMN_ENDPOINT = '/change';
const DELETE_COLUMN_ENDPOINT = '/delete';

const PRE_MARK_ENDPOINT = '/school/gradebook/mark';
const CREATE_MARK_ENDPOINT = '/school/gradebook/mark/create';
const CHANGE_MARK_ENDPOINT = '/change';
const DELETE_MARK_ENDPOINT = '/delete';

const PRE_ANALYTIC_ENDPOINT = '/school/gradebook/analytic-mark';
const CREATE_ANALYTIC_ENDPOINT = '/school/gradebook/analytic-mark/create';
const CHANGE_ANALYTIC_ENDPOINT = '/change';
const DELETE_ANALYTIC_ENDPOINT = '/delete';

const GRADEBOOKS_REDUX = 'gradebooks';
const SELECT_GRADEBOOKS_REDUX = 'gradebooks/selectAll';
const SELECT_GRADEBOOK_REDUX = 'gradebooks/selectOne';
const CHANGE_GRADEBOOK_REDUX = 'gradebooks/changeOne';
const CREATE_GRADEBOOK_REDUX = 'gradebooks/create';
const DELETE_GRADEBOOK_REDUX = 'gradebooks/delete';

const CREATE_COLUMN_REDUX = 'gradebooks/columnCreate';
const CHANGE_COLUMN_REDUX = 'gradebooks/columnChange';
const DELETE_COLUMN_REDUX = 'gradebooks/columnDelete';

const CREATE_MARK_REDUX = 'gradebooks/markCreate';
const CHANGE_MARK_REDUX = 'gradebooks/markChange';
const DELETE_MARK_REDUX = 'gradebooks/markDelete';

const CREATE_ANALYTIC_REDUX = 'gradebooks/analyticCreate';
const CHANGE_ANALYTIC_REDUX = 'gradebooks/analyticChange';
const DELETE_ANALYTIC_REDUX = 'gradebooks/analyticDelete';

export {
  PRE_GRADEBOOKS_ENDPOINT,
  SELECT_GRADEBOOKS_ENDPOINT,
  PRE_GRADEBOOK_ENDPOINT,
  SELECT_GRADEBOOK_ENDPOINT,
  CREATE_GRADEBOOK_ENDPOINT,
  PRE_COLUMN_ENDPOINT,
  CREATE_COLUMN_ENDPOINT,
  CHANGE_COLUMN_ENDPOINT,
  DELETE_COLUMN_ENDPOINT,
  PRE_MARK_ENDPOINT,
  CREATE_MARK_ENDPOINT,
  CHANGE_MARK_ENDPOINT,
  DELETE_MARK_ENDPOINT,
  GRADEBOOKS_REDUX,
  SELECT_GRADEBOOKS_REDUX,
  SELECT_GRADEBOOK_REDUX,
  CREATE_GRADEBOOK_REDUX,
  CREATE_COLUMN_REDUX,
  CHANGE_COLUMN_REDUX,
  DELETE_COLUMN_REDUX,
  CREATE_MARK_REDUX,
  CHANGE_MARK_REDUX,
  DELETE_MARK_REDUX,
  DELETE_GRADEBOOK_REDUX,
  DELETE_GRADEBOOK_ENDPOINT,
  PRE_ANALYTIC_ENDPOINT,
  CREATE_ANALYTIC_ENDPOINT,
  CHANGE_ANALYTIC_ENDPOINT,
  DELETE_ANALYTIC_ENDPOINT,
  CREATE_ANALYTIC_REDUX,
  CHANGE_ANALYTIC_REDUX,
  DELETE_ANALYTIC_REDUX,
  CHANGE_GRADEBOOK_ENDPOINT,
  CHANGE_GRADEBOOK_REDUX,
};
