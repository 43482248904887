export const LANDING_PATH = '/';

export const LOGIN_PATH = '/login';
export const SIGN_PATH = '/sign';
export const RESET_PASSWORD_PATH = '/reset-password';
export const NEW_PASSWORD_PATH = '/new-password';
export const VERIFY_EMAIL = '/verify-email';

export const HOME_PATH = '/classes';
export const ARCHIVE_PATH = '/archive';

export const GRADEBOOKS_PATH = '/classes/:id/gradebooks';
export const STUDENTS_PATH = '/classes/:id/students';
export const SCALES_PATH = '/classes/:id/scales';

export const GRADEBOOK_PATH = '/gradebook/:id';
export const CATEGORIES_PATH = '/gradebook/:id/categories';
export const COMMENTS_PATH = '/gradebook/:id/comments';
export const REPORTS_PATH = '/gradebook/:id/reports';
export const HABITS_PATH = '/gradebook/:id/habits';

export const CONTACT_US_PATH = '/contact-us';
export const TIPS_TRICKS_PATH = '/tips-and-tricks';
export const BLOG_PATH = '/blog';
export const PAYMENT_PATH = '/payment';

export const COMMING_SOON_PATH = '/comming-soon';
export const SOON_PATH = '/soon';
