const PRE_ARCHIVE_ENDPOINT = '/school/class';
const ARCHIVE_SELECT_ENDPOINT = '/school/classes/archived/info';
const UNARCHIVE_ENDPOINT = '/unarchive';

const ARCHIVE_REDUX = 'archive';
const ARCHIVE_SELECT_REDUX = 'archive/select';
const UNARCHIVE_REDUX = 'archive/unarchive';

export {
  PRE_ARCHIVE_ENDPOINT,
  ARCHIVE_SELECT_ENDPOINT,
  ARCHIVE_REDUX,
  ARCHIVE_SELECT_REDUX,
  UNARCHIVE_ENDPOINT,
  UNARCHIVE_REDUX,
};
