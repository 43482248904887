const RESEND_EMAIL_ENDPOINT = '/';
const CONFIRM_EMAIL_ENDPOINT = '/account/email/confirm';

const VERIFY_REDUX = 'verifyEmail';
const RESEND_EMAIL_REDUX = 'verifyEmail/resend';
const CONFIRM_EMAIL_REDUX = 'verifyEmail/confirm';

export {
  RESEND_EMAIL_ENDPOINT,
  VERIFY_REDUX,
  RESEND_EMAIL_REDUX,
  CONFIRM_EMAIL_ENDPOINT,
  CONFIRM_EMAIL_REDUX,
};
