import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';

import * as Routes from 'routes/path.js';

const menuList = [
  {
    icon: <GridViewOutlinedIcon />,
    label: 'Classes',
    route: Routes.HOME_PATH,
  },
  {
    icon: <BookmarkOutlinedIcon />,
    label: 'Archive',
    route: Routes.ARCHIVE_PATH,
  },
];

export default menuList;
