import { createSlice } from '@reduxjs/toolkit';

import resetPasswordAction from './ResetPassActions';
import { RESET_REDUX } from './ResetPassConstants';

const initialState = {
  resetLoading: false,
};

const resetPasswordSlice = createSlice({
  name: RESET_REDUX,
  initialState,
  reducers: {},
  extraReducers: {
    [resetPasswordAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.resetLoading = true;
    },
    [resetPasswordAction.fulfilled.type]: (state) => {
      state.resetLoading = false;
    },
    [resetPasswordAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.resetLoading = false;
    },
  },
});

export default resetPasswordSlice.reducer;
