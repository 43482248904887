import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

import { MenuItem } from 'layout/dashboardSidebar/styled';

import useLoginDispatcher from '../hooks/useLoginDispatcher';

import { LOGIN_PATH } from 'routes/path';
import DICTIONARY from 'core/Dictionary';

const menuItemStyles = { position: 'absolute', bottom: '70px' };

const LogoutModule = () => {
  const { logoutDispatcher } = useLoginDispatcher();
  const navigate = useNavigate();

  const logoutFetcher = (refreshToken) => {
    const requestState = {
      payload: {
        refresh: refreshToken,
      },
    };

    logoutDispatcher(requestState)
      .unwrap()
      .then(() => {
        toast.success('You have successfully logged out!');
        localStorage.clear();
        navigate(LOGIN_PATH);
      })
      .catch((error) => {
        toast.error(error?.detail);
      });
  };

  const handleLogout = () => {
    const refresh = localStorage.getItem(DICTIONARY.refreshToken);
    logoutFetcher(refresh);
  };

  return (
    <MenuItem onClick={handleLogout} sx={menuItemStyles}>
      <LogoutOutlinedIcon />
      <p>Logout</p>
    </MenuItem>
  );
};

export default LogoutModule;
