import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import SignUpSlice from 'pages/signup/services/SignUpSlice';
import LoginSlice from 'pages/login/services/LoginSlice';
import VerifyEmailSlice from 'pages/verifyEmail/services/VerifyEmailSlice';
import ResetPassSlice from 'pages/resetPassword/services/ResetPassSlice';
import NewPasswordSlice from 'pages/newPassword/services/NewPasswordSlice';
import ArchiveSlice from 'pages/archive/services/ArchiveSlice';
import StudentsSlice from 'pages/students/services/StudentsSlice';
import GradebookSlice from 'pages/gradebook/services/GradebookSlice';
import ClassesSlice from 'pages/classes/services/ClassesSlice';
import CommentsSlice from 'pages/comments/services/CommentsSlice';
import ScalesSlice from 'pages/scales/services/ScalesSlice';
import CategoriesSlice from 'pages/categories/services/CategoriesSlice';
import ContactUsSlice from 'pages/contactUs/services/ContactUsSlice';
import WorkHabitsSlice from 'pages/workHabits/services/WorkHabitsSlice';
import ReportsSlice from 'pages/reports/services/ReportsSlice';

const reducers = {
  signReducer: SignUpSlice,
  loginReducer: LoginSlice,
  verifyReducer: VerifyEmailSlice,
  resetPasswordReducer: ResetPassSlice,
  newPasswordReducer: NewPasswordSlice,
  classesReducer: ClassesSlice,
  archiveReducer: ArchiveSlice,
  studentsReducer: StudentsSlice,
  gradebookReducer: GradebookSlice,
  commentsReducer: CommentsSlice,
  scalesReducer: ScalesSlice,
  categoriesReducer: CategoriesSlice,
  feedbackReducer: ContactUsSlice,
  habitsReducer: WorkHabitsSlice,
  reportsReducer: ReportsSlice,
};

export default configureStore({
  reducer: reducers,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
