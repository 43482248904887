const LOGIN_ENDPOINT = '/account/token';
const GET_USER_ENDPOINT = '/account/info';
const LOGOUT_ENDPOINT = '/account/token/blacklist';

const LOGIN_REDUX = 'login';
const LOGIN_CREATE_REDUX = 'login/create';
const GET_USER_REDUX = 'login/user';
const LOGOUT_REDUX = 'login/logout';

export {
  LOGIN_ENDPOINT,
  LOGIN_REDUX,
  LOGIN_CREATE_REDUX,
  GET_USER_ENDPOINT,
  GET_USER_REDUX,
  LOGOUT_ENDPOINT,
  LOGOUT_REDUX,
};
