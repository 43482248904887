import { get_request, patch_request } from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  PRE_CLASS_ENDPOINT,
  PRE_PROFICIENCY_ENDPOINT,
  PRE_SCALE_ENDPOINT,
  PROFICIENCY_NAME_CHANGE_REDUX,
  PROFICIENCY_UPDATE_REDUX,
  SCALES_SELECT_REDUX,
} from './ScalesConstants.js';

const selectScalesAction = get_request(
  SCALES_SELECT_REDUX,
  PRE_CLASS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeProficiencyAction = patch_request(
  PROFICIENCY_UPDATE_REDUX,
  PRE_SCALE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeProficiencyNameAction = patch_request(
  PROFICIENCY_NAME_CHANGE_REDUX,
  PRE_PROFICIENCY_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export { changeProficiencyAction, changeProficiencyNameAction };
export default selectScalesAction;
