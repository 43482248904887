/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';

import { LOGIN_PATH } from 'routes/path';
import DICTIONARY from 'core/Dictionary';

import { jwtDecoder } from 'utils/helper';
import ScreenLoader from 'UI/ScreenLoader/ScreenLoader';

const PrivateGuard = ({ children }) => {
  const [isAuth, setIsAuth] = useState(null);
  const isAuthNull = isAuth === null;
  const isNotLogout = localStorage.length > 0;

  useEffect(() => {
    const access = localStorage.getItem(DICTIONARY.accessToken);
    const refresh = localStorage.getItem(DICTIONARY.refreshToken);
    const { isValidAccess, isValidRefresh } = jwtDecoder(access, refresh);

    if (isValidAccess) setIsAuth(true);
    else if (!isValidRefresh || !isNotLogout) setIsAuth(false);
    else setIsAuth(true);
  }, []);

  if (isAuthNull && isNotLogout) return <ScreenLoader />;
  else if (isAuth) return children;
  else return <Navigate to={LOGIN_PATH} replace />;

  // if (!token) return <Navigate to={LOGIN_PATH} replace />;

  // return children;
};

export default PrivateGuard;
