const SIGN_ENDPOINT = '/account/registration';
const EDUCATION_TYPE_ENDPOINT = '/account/education-types';

const SIGN_REDUX = 'sign';
const SIGN_CREATE_REDUX = 'sign/create';
const EDUCATION_TYPE_REDUX = 'sign/education';

export {
  SIGN_ENDPOINT,
  SIGN_REDUX,
  SIGN_CREATE_REDUX,
  EDUCATION_TYPE_ENDPOINT,
  EDUCATION_TYPE_REDUX,
};
