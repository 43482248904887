import {
  delete_request,
  get_request,
  patch_request,
  post_request,
  put_request,
} from 'api/Requests.js';

import {
  CHANGE_ANALYTIC_REDUX,
  CHANGE_COLUMN_REDUX,
  CHANGE_GRADEBOOK_REDUX,
  CHANGE_MARK_REDUX,
  CREATE_ANALYTIC_ENDPOINT,
  CREATE_ANALYTIC_REDUX,
  CREATE_COLUMN_ENDPOINT,
  CREATE_COLUMN_REDUX,
  CREATE_GRADEBOOK_ENDPOINT,
  CREATE_GRADEBOOK_REDUX,
  CREATE_MARK_ENDPOINT,
  CREATE_MARK_REDUX,
  DELETE_ANALYTIC_REDUX,
  DELETE_COLUMN_REDUX,
  DELETE_GRADEBOOK_REDUX,
  DELETE_MARK_REDUX,
  PRE_ANALYTIC_ENDPOINT,
  PRE_COLUMN_ENDPOINT,
  PRE_GRADEBOOKS_ENDPOINT,
  PRE_GRADEBOOK_ENDPOINT,
  PRE_MARK_ENDPOINT,
  SELECT_GRADEBOOKS_REDUX,
  SELECT_GRADEBOOK_REDUX,
} from './GradebookConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectAllGradebooksAction = get_request(
  SELECT_GRADEBOOKS_REDUX,
  PRE_GRADEBOOKS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectOneGradebookAction = get_request(
  SELECT_GRADEBOOK_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeGradebookAction = put_request(
  CHANGE_GRADEBOOK_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createGradebookAction = post_request(
  CREATE_GRADEBOOK_REDUX,
  CREATE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createColumnAction = post_request(
  CREATE_COLUMN_REDUX,
  CREATE_COLUMN_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeColumnAction = put_request(
  CHANGE_COLUMN_REDUX,
  PRE_COLUMN_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteColumnAction = delete_request(
  DELETE_COLUMN_REDUX,
  PRE_COLUMN_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createMarkAction = post_request(
  CREATE_MARK_REDUX,
  CREATE_MARK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeMarkAction = put_request(
  CHANGE_MARK_REDUX,
  PRE_MARK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteMarkAction = delete_request(
  DELETE_MARK_REDUX,
  PRE_MARK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteGradebookAction = delete_request(
  DELETE_GRADEBOOK_REDUX,
  PRE_GRADEBOOK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createAnalyticAction = post_request(
  CREATE_ANALYTIC_REDUX,
  CREATE_ANALYTIC_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeAnalyticAction = patch_request(
  CHANGE_ANALYTIC_REDUX,
  PRE_ANALYTIC_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteAnalyticAction = delete_request(
  DELETE_ANALYTIC_REDUX,
  PRE_ANALYTIC_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  changeGradebookAction,
  selectOneGradebookAction,
  createGradebookAction,
  createColumnAction,
  createAnalyticAction,
  changeColumnAction,
  changeAnalyticAction,
  deleteColumnAction,
  createMarkAction,
  changeMarkAction,
  deleteMarkAction,
  deleteGradebookAction,
  deleteAnalyticAction,
};
export default selectAllGradebooksAction;
