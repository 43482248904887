import { get_request, post_request } from 'api/Requests.js';

import {
  EDUCATION_TYPE_ENDPOINT,
  EDUCATION_TYPE_REDUX,
  SIGN_CREATE_REDUX,
  SIGN_ENDPOINT,
} from './SignUpConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const signUpAction = post_request(
  SIGN_CREATE_REDUX,
  SIGN_ENDPOINT,
  DICTIONARY.pureRequest,
);

const selectEducationTypeAction = get_request(
  EDUCATION_TYPE_REDUX,
  EDUCATION_TYPE_ENDPOINT,
  DICTIONARY.pureRequest,
);

export { selectEducationTypeAction };
export default signUpAction;
