import React, { useEffect, useState } from 'react';

import DashboardBreadcrumbs from 'layout/dashboardBreadcrumbs/DashboardBreadcrumbs';
import DashboardNavbarRoot, { LogoRoot, ToolbarRoot } from './styled';
import ProfilePopover from 'layout/profilePopover/ProfilePopover';
import { Paragraph } from 'UI/Typography';
import Flexbox from 'UI/Flexbox/Flexbox';
import themePalette from 'theme/Palette';

const DashboardNavbar = ({ user }) => {
  const [subDeactive, setSubDeactive] = useState(false);
  const subscriptionDate = new Date(user?.last_subscription_date)
    .toString()
    .substring(0, 15);

  const userName = user?.first_name + ' ' + user?.last_name;

  const email = user?.email;

  useEffect(() => {
    const subDateIso = new Date(user?.last_subscription_date);
    const nowDate = new Date();

    const subDate = Math.abs(subDateIso - nowDate) / 86400000;

    if (subDate <= 7.1) setSubDeactive(true);
    else setSubDeactive(false);
  }, [user]);

  return (
    <DashboardNavbarRoot>
      <LogoRoot></LogoRoot>

      <ToolbarRoot>
        <DashboardBreadcrumbs />

        <Flexbox gap="20px" alignItems="center">
          <Flexbox
            flexDirection="column"
            gap="1px"
            alignItems="end"
            sx={{ marginRight: '40px' }}
          >
            <Paragraph
              text="Subscription end date:"
              lineHeight="1.2"
              fontWeight="400"
              fontSize="13px"
            />
            <Paragraph
              text={subscriptionDate}
              lineHeight="1.2"
              fontWeight="500"
              fontSize="12px"
              overrideStyles={{
                color: subDeactive ? themePalette.red : '#64a36a',
              }}
            />
          </Flexbox>
          <Flexbox flexDirection="column" alignItems="end">
            <Paragraph
              text={userName}
              lineHeight="1.2"
              fontWeight="500"
              fontSize="14px"
            />
            <Paragraph
              text={email}
              lineHeight="1.2"
              fontWeight="400"
              fontSize="12px"
            />
          </Flexbox>
          <ProfilePopover src={null} overrideStyles={{ marginLeft: 'auto' }} />
        </Flexbox>
      </ToolbarRoot>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
