import { MenuItem } from 'layout/dashboardSidebar/styled';

const DashboardMenuItem = ({ label, icon, route, onClick, active }) => {
  return (
    <MenuItem onClick={onClick} active={active}>
      {icon}
      <p>{label}</p>
    </MenuItem>
  );
};

export default DashboardMenuItem;
