import { styled } from '@mui/material';

import themePalette from 'theme/Palette';

const LandingHeader = styled('header')(() => ({ padding: '30px 0' }));

export const ContainerHeader = styled('div')(() => ({
  padding: '0 80px',
  position: 'relative',
  zIndex: 10,
  '@media (max-width: 1100px)': {
    padding: '0 30px',
  },
}));

export const HeaderLink = styled('div')(() => ({
  fontFamily: 'Roboto',
  lineHeight: 1.2,
  fontSize: '17px',
  fontWeight: 400,
  cursor: 'pointer',
  transition: 'all .3s',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const HeaderSignUpLink = styled('div')(() => ({
  fontFamily: 'Roboto',
  lineHeight: 1.2,
  fontSize: '17px',
  cursor: 'pointer',
  fontWeight: 400,
  backgroundColor: themePalette.primaryMuted,
  padding: '6px 10px',
  color: themePalette.primary,
  transition: 'all .3s',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default LandingHeader;
