import {
  delete_request,
  get_request,
  post_request,
  put_request,
} from 'api/Requests.js';

import {
  CLASSES_SECONDARY_ARCHIVE_REDUX,
  CLASSES_SECONDARY_CHANGE_ENDPOINT,
  CLASSES_SECONDARY_CHANGE_REDUX,
  CLASSES_SECONDARY_COPY_ENDPOINT,
  CLASSES_SECONDARY_COPY_REDUX,
  CLASSES_SECONDARY_CREATE_ENDPOINT,
  CLASSES_SECONDARY_CREATE_REDUX,
  CLASSES_SECONDARY_DELETE_REDUX,
  CLASSES_SECONDARY_SELECT_ENDPOINT,
  CLASSES_SECONDARY_SELECT_REDUX,
  PRE_CLASSES_ENDPOINT,
} from './ClassesConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectSecondaryAction = get_request(
  CLASSES_SECONDARY_SELECT_REDUX,
  CLASSES_SECONDARY_SELECT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const copyClassAction = post_request(
  CLASSES_SECONDARY_COPY_REDUX,
  CLASSES_SECONDARY_COPY_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const createSecondaryAction = post_request(
  CLASSES_SECONDARY_CREATE_REDUX,
  CLASSES_SECONDARY_CREATE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeSecondaryAction = put_request(
  CLASSES_SECONDARY_CHANGE_REDUX,
  CLASSES_SECONDARY_CHANGE_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const deleteSecondaryAction = delete_request(
  CLASSES_SECONDARY_DELETE_REDUX,
  PRE_CLASSES_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const archiveSecondaryAction = put_request(
  CLASSES_SECONDARY_ARCHIVE_REDUX,
  PRE_CLASSES_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  createSecondaryAction,
  changeSecondaryAction,
  deleteSecondaryAction,
  archiveSecondaryAction,
  copyClassAction,
};
export default selectSecondaryAction;
