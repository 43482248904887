import { createAsyncThunk } from '@reduxjs/toolkit';

import { pureInstance, tokenInstance } from './Instance.js';
import { decodeArrayParameters } from './HeaderApi.js';
import DICTIONARY from 'core/Dictionary.js';

const get_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let apiUrl = endpoint;
      const { params, paramsArray, id } = state;

      if (id) apiUrl = endpoint + '/' + id;

      if (paramsArray) {
        apiUrl = decodeArrayParameters(paramsArray, apiUrl);
      }

      if (type === DICTIONARY.pureRequest)
        response = await pureInstance.get(apiUrl, { params });
      else response = await tokenInstance.get(apiUrl, { params });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const post_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let apiUrl = endpoint;
      const { params, id, payload } = state;

      if (id) apiUrl = endpoint + '/' + id;

      if (type === DICTIONARY.pureRequest)
        response = await pureInstance.post(apiUrl, payload, { params });
      else response = await tokenInstance.post(apiUrl, payload, { params });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const put_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let apiUrl = endpoint;
      const { params, id, payload } = state;

      if (id) apiUrl = endpoint + '/' + id;

      if (type === DICTIONARY.pureRequest)
        response = await pureInstance.put(apiUrl, payload, { params });
      else response = await tokenInstance.put(apiUrl, payload, { params });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const patch_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let apiUrl = endpoint;
      const { params, id, payload } = state;

      if (id) apiUrl = endpoint + '/' + id;

      if (type === DICTIONARY.pureRequest)
        response = await pureInstance.patch(apiUrl, payload, { params });
      else response = await tokenInstance.patch(apiUrl, payload, { params });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

const delete_request = (prefix, endpoint, type) => {
  return createAsyncThunk(prefix, async (state, thunkAPI) => {
    try {
      let response = null;
      let apiUrl = endpoint;
      const { params, id } = state;

      if (id) apiUrl = endpoint + '/' + id;

      if (type === DICTIONARY.pureRequest)
        response = await pureInstance.delete(apiUrl, { params });
      else response = await tokenInstance.delete(apiUrl, { params });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  });
};

export {
  get_request,
  post_request,
  patch_request,
  put_request,
  delete_request,
};
