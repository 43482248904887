import { post_request } from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  NEW_PASSWORD_CREATE_REDUX,
  NEW_PASSWORD_ENDPOINT,
} from './NewPasswordConstants.js';

const newPasswordAction = post_request(
  NEW_PASSWORD_CREATE_REDUX,
  NEW_PASSWORD_ENDPOINT,
  DICTIONARY.pureRequest,
);

export default newPasswordAction;
