import React, { Fragment } from 'react';

import LandingNavbar from './landingNavbar/LandingNavbar';

const LandingLayout = ({ children, auth = false }) => {
  return (
    <Fragment>
      <LandingNavbar auth={auth} />
      {children}
    </Fragment>
  );
};

export default LandingLayout;
