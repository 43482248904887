const PRE_CLASSES_ENDPOINT = '/school/class';
const CLASSES_SECONDARY_SELECT_ENDPOINT = '/school/classes/info';
const CLASSES_SECONDARY_CREATE_ENDPOINT = '/school/class/create';
const CLASSES_SECONDARY_CHANGE_ENDPOINT = '/school/class/change';
const CLASSES_SECONDARY_DELETE_ENDPOINT = '/delete';
const CLASSES_SECONDARY_ARCHIVE_ENDPOINT = '/archive';
const CLASSES_SECONDARY_COPY_ENDPOINT = '/school/class/copy';

const CLASSES_REDUX = 'classes';
const CLASSES_SECONDARY_SELECT_REDUX = 'classes/secondary';
const CLASSES_SECONDARY_CREATE_REDUX = 'classes/secondaryCreate';
const CLASSES_SECONDARY_CHANGE_REDUX = 'classes/secondaryChange';
const CLASSES_SECONDARY_DELETE_REDUX = 'classes/secondaryDelete';
const CLASSES_SECONDARY_ARCHIVE_REDUX = 'classes/secondaryArchive';
const CLASSES_SECONDARY_COPY_REDUX = 'classes/copy';

export {
  PRE_CLASSES_ENDPOINT,
  CLASSES_SECONDARY_SELECT_ENDPOINT,
  CLASSES_SECONDARY_COPY_ENDPOINT,
  CLASSES_REDUX,
  CLASSES_SECONDARY_COPY_REDUX,
  CLASSES_SECONDARY_SELECT_REDUX,
  CLASSES_SECONDARY_CREATE_ENDPOINT,
  CLASSES_SECONDARY_CHANGE_ENDPOINT,
  CLASSES_SECONDARY_DELETE_ENDPOINT,
  CLASSES_SECONDARY_ARCHIVE_ENDPOINT,
  CLASSES_SECONDARY_CREATE_REDUX,
  CLASSES_SECONDARY_CHANGE_REDUX,
  CLASSES_SECONDARY_DELETE_REDUX,
  CLASSES_SECONDARY_ARCHIVE_REDUX,
};
