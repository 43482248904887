import { post_request } from 'api/Requests.js';

import DICTIONARY from 'core/Dictionary.js';
import {
  CREATE_FEEDBACK_ENDPOINT,
  CREATE_FEEDBACK_REDUX,
} from './ContactUsConstants.js';

const createFeedbackAction = post_request(
  CREATE_FEEDBACK_REDUX,
  CREATE_FEEDBACK_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export default createFeedbackAction;
