import { createSlice } from '@reduxjs/toolkit';

import { GRADEBOOKS_REDUX } from './GradebookConstants';
import selectAllGradebooksAction, {
  selectOneGradebookAction,
} from './GradebookActions';

const initialState = {
  gradebooksAllUpdating: false,
  gradebookOneUpdating: false,
};

const gradebookSlice = createSlice({
  name: GRADEBOOKS_REDUX,
  initialState,
  reducers: {
    setGradebooksAllUpdating: (state, payload) => {
      state.gradebooksAllUpdating = payload;
    },
    setGradebooksOneUpdating: (state, payload) => {
      state.gradebookOneUpdating = payload;
    },
  },
  extraReducers: {
    [selectAllGradebooksAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectAllGradebooksAction.fulfilled.type]: (state) => {
      state.gradebooksAllUpdating = false;
    },
    [selectAllGradebooksAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.gradebooksAllUpdating = false;
    },

    [selectOneGradebookAction.pending.type]: (state) => {
      Object.assign(state, initialState);
    },
    [selectOneGradebookAction.fulfilled.type]: (state) => {
      state.gradebookOneUpdating = false;
    },
    [selectOneGradebookAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.gradebookOneUpdating = false;
    },
  },
});

export const { setGradebooksAllUpdating, setGradebooksOneUpdating } =
  gradebookSlice.actions;
export default gradebookSlice.reducer;
