import { Box, styled } from '@mui/material';

const DashboardWrapper = styled(Box)(({ theme }) => ({
  width: `calc(100% - ${300}px)`,
  paddingLeft: '15px',
  paddingTop: '25px',
  paddingRight: '50px',
  transition: 'all 0.3s',
  marginBottom: '50px',
  marginLeft: 300,
  [theme.breakpoints.down(1200)]: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

export default DashboardWrapper;
