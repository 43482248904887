import { get_request, patch_request, post_request } from 'api/Requests.js';

import {
  CHANGE_COMMENTS_REDUX,
  CHANGE_ENGAGEMENT_REDUX,
  CHANGE_WORKHABIT_REDUX,
  GENERATE_AI_ENGAGEMENT_REDUX,
  GENERATE_AI_REDUX,
  GENERATE_AI_WORKHABIT_REDUX,
  PRE_CLASS_ENDPOINT,
  PRE_COMMENTS_ENDPOINT,
  PRE_ENGAGEMENT_ENDPOINT,
  PRE_PURE_ENDGAGEMENT_ENDPOINT,
  PRE_WORKHABIT_ENDPOINT,
  SELECT_COMMENTS_REDUX,
  SELECT_ENGAGEMENT_REDUX,
  SELECT_WORKHABIT_REDUX,
} from './CommentsConstants.js';
import DICTIONARY from 'core/Dictionary.js';

const selectCommentsAction = get_request(
  SELECT_COMMENTS_REDUX,
  PRE_CLASS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeCommentsAction = patch_request(
  CHANGE_COMMENTS_REDUX,
  PRE_COMMENTS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectWorkhabitsAction = get_request(
  SELECT_WORKHABIT_REDUX,
  PRE_CLASS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeWorkhabitsAction = patch_request(
  CHANGE_WORKHABIT_REDUX,
  PRE_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const selectEngagementsAction = get_request(
  SELECT_ENGAGEMENT_REDUX,
  PRE_PURE_ENDGAGEMENT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const changeEngagementsAction = patch_request(
  CHANGE_ENGAGEMENT_REDUX,
  PRE_ENGAGEMENT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const generateCommentAIAction = post_request(
  GENERATE_AI_REDUX,
  PRE_COMMENTS_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const generateWorkhabitAIAction = post_request(
  GENERATE_AI_WORKHABIT_REDUX,
  PRE_WORKHABIT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

const generateEngagementAIAction = post_request(
  GENERATE_AI_ENGAGEMENT_REDUX,
  PRE_ENGAGEMENT_ENDPOINT,
  DICTIONARY.tokenRequest,
);

export {
  selectWorkhabitsAction,
  selectEngagementsAction,
  changeCommentsAction,
  changeWorkhabitsAction,
  changeEngagementsAction,
  generateCommentAIAction,
  generateWorkhabitAIAction,
  generateEngagementAIAction,
};
export default selectCommentsAction;
