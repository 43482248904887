const PRE_SELECT_ENDPOINT = '/school/class';
const PRE_STUDENTS_ENDPOINT = '/school/student';
const STUDENTS_SELECT_ENDPOINT = '/students/info';
const STUDENTS_CREATE_ENDPOINT = '/school/student/create';
const STUDENTS_UPDATE_ENDPOINT = '/change';
const STUDENTS_DELETE_ENDPOINT = '/delete';

const GENDER_SELECT_ENDPOINT = '/school/gender/info';
const GSNI_SELECT_ENDPOINT = '/school/gsni/info';

const STUDENTS_REDUX = 'students';
const STUDENTS_SELECT_REDUX = 'students/info';
const STUDENTS_CREATE_REDUX = 'students/create';
const STUDENTS_UPDATE_REDUX = 'students/change';
const STUDENTS_DELETE_REDUX = 'students/delete';

const GENDER_SELECT_REDUX = 'students/gender';
const GSNI_SELECT_REDUX = 'students/gsni';

export {
  PRE_STUDENTS_ENDPOINT,
  PRE_SELECT_ENDPOINT,
  STUDENTS_SELECT_ENDPOINT,
  STUDENTS_CREATE_ENDPOINT,
  STUDENTS_UPDATE_ENDPOINT,
  STUDENTS_DELETE_ENDPOINT,
  GENDER_SELECT_ENDPOINT,
  GSNI_SELECT_ENDPOINT,
  STUDENTS_REDUX,
  STUDENTS_SELECT_REDUX,
  STUDENTS_CREATE_REDUX,
  STUDENTS_UPDATE_REDUX,
  STUDENTS_DELETE_REDUX,
  GENDER_SELECT_REDUX,
  GSNI_SELECT_REDUX,
};
