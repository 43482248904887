import { lazy } from 'react';
import * as Routes from './path';

import PrivateGuard from 'components/privateGuard/PrivateGuard';
import DashboardLayout from 'layout/DashboardLayout';
import LandingLayout from 'layout/LandingLayout';
import CommingSoon from 'pages/commingSoon/CommingSoon';

const LazyLanding = lazy(() => import('pages/landing/Landing'));

const LazyLogin = lazy(() => import('pages/login/Login'));
const LazySign = lazy(() => import('pages/signup/SignUp'));
const LazyNewPassword = lazy(() => import('pages/newPassword/NewPassword'));
const LazyVerifyEmail = lazy(() => import('pages/verifyEmail/VerifyEmail'));
const LazyResetPassword = lazy(() =>
  import('pages/resetPassword/ResetPassword'),
);

const LazyClasses = lazy(() => import('pages/classes/Classses'));
const LazyArchive = lazy(() => import('pages/archive/Archive'));

const LazyStudents = lazy(() => import('pages/students/Students'));
const LazyScales = lazy(() => import('pages/scales/Scales'));
const LazyGradebooks = lazy(() => import('pages/gradebooks/Gradebooks'));

const LazyGradebook = lazy(() => import('pages/gradebook/Gradebook'));
const LazyCategories = lazy(() => import('pages/categories/Categories'));
const LazyComments = lazy(() => import('pages/comments/Comments'));
const LazyReports = lazy(() => import('pages/reports/Reports'));
const LazyHabits = lazy(() => import('pages/workHabits/WorkHabits'));

const LazyContactUs = lazy(() => import('pages/contactUs/ContactUs'));

const routes = [
  {
    path: Routes.HOME_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyClasses />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.STUDENTS_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyStudents />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.CATEGORIES_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyCategories />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.ARCHIVE_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyArchive />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.GRADEBOOKS_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyGradebooks />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.COMMENTS_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyComments />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.REPORTS_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyReports />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.GRADEBOOK_PATH,
    element: (
      <PrivateGuard>
        <LazyGradebook />
      </PrivateGuard>
    ),
  },
  {
    path: Routes.LANDING_PATH,
    element: (
      <LandingLayout>
        <LazyLanding />
      </LandingLayout>
    ),
  },
  {
    path: Routes.SCALES_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyScales />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.HABITS_PATH,
    element: (
      <PrivateGuard>
        <DashboardLayout>
          <LazyHabits />
        </DashboardLayout>
      </PrivateGuard>
    ),
  },
  {
    path: Routes.TIPS_TRICKS_PATH,
    element: (
      <LandingLayout auth>
        <CommingSoon />
      </LandingLayout>
    ),
  },
  {
    path: Routes.BLOG_PATH,
    element: (
      <LandingLayout auth>
        <CommingSoon />
      </LandingLayout>
    ),
  },
  {
    path: Routes.PAYMENT_PATH,
    element: (
      <LandingLayout auth>
        <CommingSoon />
      </LandingLayout>
    ),
  },
  {
    path: Routes.CONTACT_US_PATH,
    element: <LazyContactUs />,
  },
  {
    path: Routes.COMMING_SOON_PATH,
    element: (
      <LandingLayout>
        <CommingSoon />
      </LandingLayout>
    ),
  },
  {
    path: Routes.SOON_PATH,
    element: <CommingSoon returnable />,
  },
  { path: Routes.LOGIN_PATH, element: <LazyLogin /> },
  { path: Routes.SIGN_PATH, element: <LazySign /> },
  { path: Routes.RESET_PASSWORD_PATH, element: <LazyResetPassword /> },
  { path: Routes.NEW_PASSWORD_PATH, element: <LazyNewPassword /> },
  { path: Routes.VERIFY_EMAIL, element: <LazyVerifyEmail /> },
];

export default routes;
