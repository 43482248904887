import { createSlice } from '@reduxjs/toolkit';

import { VERIFY_REDUX } from './VerifyEmailConstants';
import resendEmailAction from './VerifyEmailActions';

const initialState = {
  verifyLoading: false,
};

const verifyEmailSlice = createSlice({
  name: VERIFY_REDUX,
  initialState,
  reducers: {
    setVerifyFetching: (state, payload) => {
      state.verifyLoading = payload;
    },
  },
  extraReducers: {
    [resendEmailAction.pending.type]: (state) => {
      Object.assign(state, initialState);
      state.verifyLoading = true;
    },
    [resendEmailAction.fulfilled.type]: (state) => {
      state.verifyLoading = false;
    },
    [resendEmailAction.rejected.type]: (state) => {
      Object.assign(state, initialState);
      state.verifyLoading = false;
    },
  },
});

export const { setVerifyFetching } = verifyEmailSlice.actions;
export default verifyEmailSlice.reducer;
